<template>
  <div id="app" class="font-sans text-gray-800">
    <div class="flex justify-center my-4">
      <input type="date" v-model="selectedDay" @change="fetchData" class="mx-2 p-2 bg-white border border-gray-300 rounded shadow">
      <select v-model="selectedChannel" @change="fetchData" class="mx-2 p-2 bg-white border border-gray-300 rounded shadow">
        <option value="">Select Channel</option>
        <option v-for="channel in channels" :key="channel" :value="channel">{{ channel }}</option>
      </select>
    </div>
    <div class="overflow-x-auto">
      <table class="w-full text-left table-auto">
        <thead>
          <tr class="bg-gray-100">
            <th class="px-4 py-2">ID</th>
            <th class="px-4 py-2">项目</th>
            <th class="px-4 py-2">渠道</th>
            <th class="px-4 py-2">访问量</th>
            <th class="px-4 py-2">点击量</th>
            <th class="px-4 py-2">安装量</th>
            <th class="px-4 py-2">注册量</th>
            <th class="px-4 py-2">小时</th>
            <th class="px-4 py-2">日期</th>
            
          </tr>
        </thead>
        <tbody>
          <tr v-for="report in reports" :key="report.id" class="hover:bg-gray-50">
            <td class="border px-4 py-2">{{ report.id }}</td>
            <td class="border px-4 py-2">{{ report.item }}</td>
            <td class="border px-4 py-2">{{ report.channel }}</td>
            <td class="border px-4 py-2">{{ report.visitCount }}</td>
            <td class="border px-4 py-2">{{ report.clickCount }}</td>
            <td class="border px-4 py-2">{{ report.installCount }}</td>
            <td class="border px-4 py-2">{{ report.registerCount }}</td>
            <td class="border px-4 py-2">{{ report.hour }}</td>
            <td class="border px-4 py-2">{{ formatDate(report.day) }}</td>
            
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      reports: [],
      channels: ['802', '803', '804', '805', '813', '829'],
      selectedDay: new Date().toISOString().substring(0, 10),
      selectedChannel: "",
    };
  },
  methods: {
    formatDate(date) {
      if (!date) return '';
      const d = new Date(date);
      let month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();
  
      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;
  
      return [year, month, day].join('-');
    },
    fetchData() {
      axios.get(`https://opreport.cc/reports`, {
          params: {
              day: this.selectedDay,
              channel: this.selectedChannel,
          },
      })
      .then((response) => {
          this.reports = response.data;
      })
      .catch((error) => {
          console.error("There was an error fetching the reports:", error);
      });
    }
  },
  mounted() {
    this.fetchData(); // Fetch data
  },
};
</script>

<style>
@import "@/assets/styles.css";
</style>